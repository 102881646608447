.Login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
.Login_logo{
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
    background-image: url('../../public/images/logo.png');
    background-size: cover;
    background-position: center;
    border-radius: 100px;
    
}
.Login_zone{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    overflow: hidden;
}
.Login_title{
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    width: 100%;
    background-color: #c60000;
    color: #FFF;
    padding: 10px;
    text-transform: uppercase;
    margin-bottom: 15px;
}
.Login_form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
}
.Login_form input{
    width: 100%;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid #AAA;
}
.Login_form button{
    width: 100%;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid #555;
    background-color: #555;
    color: #FFF;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s;
}
.Login_form button:hover{
    background-color: #333;
    border: 1px solid #333;
    transition: 0.3s;
}
