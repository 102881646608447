/* animation neon rouge opacité 5% max 15% */
@keyframes neon {
    0% {
        color: #c60000;
        text-shadow: 0 0 5px rgba(198, 0, 0, 0.8), 0 0 10px rgba(198, 0, 0, 0.8), 0 0 15px rgba(198, 0, 0, 0.8), 0 0 20px rgba(198, 0, 0, 0.8), 0 0 35px rgba(198, 0, 0, 0.8), 0 0 40px rgba(198, 0, 0, 0.8), 0 0 50px rgba(198, 0, 0, 0.8), 0 0 75px rgba(198, 0, 0, 0.8);
    }
    30% {
        color: #c60000;
        text-shadow: 0 0 5px rgba(198, 0, 0, 0.8), 0 0 10px rgba(198, 0, 0, 0.8), 0 0 15px rgba(198, 0, 0, 0.8), 0 0 20px rgba(198, 0, 0, 0.8), 0 0 35px rgba(198, 0, 0, 0.8), 0 0 40px rgba(198, 0, 0, 0.8), 0 0 50px rgba(198, 0, 0, 0.8), 0 0 75px rgba(198, 0, 0, 0.8);
    }
    31% {
        color: #c60000;
        text-shadow: none;
    }
    33% {
        color: #c60000;
        text-shadow: 0 0 5px rgba(198, 0, 0, 0.8), 0 0 10px rgba(198, 0, 0, 0.8), 0 0 15px rgba(198, 0, 0, 0.8), 0 0 20px rgba(198, 0, 0, 0.8), 0 0 35px rgba(198, 0, 0, 0.8), 0 0 40px rgba(198, 0, 0, 0.8), 0 0 50px rgba(198, 0, 0, 0.8), 0 0 75px rgba(198, 0, 0, 0.8);
    }
    34% {
        color: #c60000;
        text-shadow: none;
    }
    35% {
        color: #c60000;
        text-shadow: 0 0 5px rgba(198, 0, 0, 0.8), 0 0 10px rgba(198, 0, 0, 0.8), 0 0 15px rgba(198, 0, 0, 0.8), 0 0 20px rgba(198, 0, 0, 0.8), 0 0 35px rgba(198, 0, 0, 0.8), 0 0 40px rgba(198, 0, 0, 0.8), 0 0 50px rgba(198, 0, 0, 0.8), 0 0 75px rgba(198, 0, 0, 0.8);
    }
    100% {
        color: rgb(198, 0, 0);
        text-shadow: 0 0 5px rgba(198, 0, 0, 0.8), 0 0 10px rgba(198, 0, 0, 0.8), 0 0 15px rgba(198, 0, 0, 0.8), 0 0 20px rgba(198, 0, 0, 0.8), 0 0 35px rgba(198, 0, 0, 0.8), 0 0 40px rgba(198, 0, 0, 0.8), 0 0 50px rgba(198, 0, 0, 0.8), 0 0 75px rgba(198, 0, 0, 0.8);
    }
}
/* animation neon blanc opacité 5% max 15% */
@keyframes neon_white {
    0% {
        color: #FFF;
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 35px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.5), 0 0 50px rgba(255, 255, 255, 0.5), 0 0 75px rgba(255, 255, 255, 0.5);
    }
    50% {
        color: #FFF;
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 35px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.5), 0 0 50px rgba(255, 255, 255, 0.5), 0 0 75px rgba(255, 255, 255, 0.5);
    }
    51% {
        color: #FFF;
        text-shadow: none;
    }
    53% {
        color: #FFF;
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 35px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.5), 0 0 50px rgba(255, 255, 255, 0.5), 0 0 75px rgba(255, 255, 255, 0.5);
    }
    54% {
        color: #FFF;
        text-shadow: none;
    }
    55% {
        color: #FFF;
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 35px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.5), 0 0 50px rgba(255, 255, 255, 0.5), 0 0 75px rgba(255, 255, 255, 0.5);
    }
    100% {
        color: #FFF;
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 10px rgba(255, 255, 255, 0.5), 0 0 15px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.5), 0 0 35px rgba(255, 255, 255, 0.5), 0 0 40px rgba(255, 255, 255, 0.5), 0 0 50px rgba(255, 255, 255, 0.5), 0 0 75px rgba(255, 255, 255, 0.5);
    }
}
.Menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: 10px;
    /* fond en dégradé de haut en bas du noir 50% d'opacité au noir noir 0% d'opacité */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    pointer-events: none;
    overflow: hidden;
}
.menu_space{
    width: calc(1.6rem + 30px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo{
    width: 65px;
    height: 65px;
    margin: 10px;
    border-radius: 80px;
    background-image: url('../../public/images/logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.menu_burger{
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: all;
}
.menu_burger_japanese{
    font-size: 0.8rem;
    color: #c60000;
    z-index: 100;
    padding: 10px;
}
.menu_burger_title{
    font-family: 'Poppins', sans-serif;
    font-size: 0.7rem;
    background-color: #c60000;
    color: #FFF;
    padding: 10px;
    border-radius: 3px;
    text-transform: uppercase;
}
.menu_open{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 100;
    transition: 0.3s;
}
.menu_close{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 100vw;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    z-index: 100;
    transition: 0.3s;
}
.menu_open_top{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: top;
    width: 100%;
    padding: 10px;
}
.menu_open_top_japanese{
    color: #c60000;
    font-size: 2.8rem;
    font-weight: 700;
    /* animation neon scintillement */
    animation: neon 5s infinite linear alternate;
}
.menu_open_top_cross{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 2.8rem;
    color: #FFF;
    padding: 10px;
    margin-top: 10px;
    font-family: "Popins", sans-serif;
    cursor: pointer;
}
.menu_open_middle{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    color: #FFF;
}
.menu_open_line{
    width: 100%;
    transition: 0.3s;
    font-size: 1.2rem;
    padding: 15px;
    color: #FFF;
}
.menu_open_line:hover{
    background-color: rgba(255, 255, 255, 0.1);
    color: #FFF!important;
    transition: 0.3s;
}
.menu_burger_line:active{
    background-color: rgba(255, 255, 255, 1);
    color: #000!important;
    transition: 0.3s;
    text-decoration: none;
}
.menu_burger_line:visited{
    color: #FFF;
}
.menu_open_bottom{
    flex: 1;
    display: flex;
    justify-content: right;
    align-items: bottom;
    width: 100%;
    padding: 10px;
}
.menu_open_bottom_title{
    font-family: 'Poppins', sans-serif;
    font-size: 2.8rem;
    color: #FFF;
    padding: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Popins", sans-serif;
    animation: neon_white 5s infinite linear alternate;
}
@media screen and (min-width: 768px){
    .logo{
        width: 90px;
        height: 90px;
    }
    .menu_open_top{
        max-width: 800px;
    }
    .menu_open_middle{
        max-width: 800px;
    }
    .menu_open_bottom{
        max-width: 800px;
    }
}