.OnePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    background-color: #f5f5f5;
    scroll-behavior: smooth;
}
.OnePage_video_screen{
    width: 100vw;
    height: 100vh;
    background-image: url(../../public/images/background.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.OnePage_video_background{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.7);
    color: #f5f5f5;

}
.OnePage_Title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    font-family: 'Poppins', sans-serif;
}
.OnePage_Title_left {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    width: calc(10px + 00.8rem);
    padding: 5px;
    border: solid 1px #c60000;
    animation: neon_white 3s infinite alternate;
}
.OnePage_Title_right {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: 1.3rem;
    margin-left: 15px;
}
.OnePage_japanese_title {
    font-size: 2rem;
    color: #c60000;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    letter-spacing: 10px;
}
.OnePage_Title_right h1{
    font-size: 2.8rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
}
.OnePage_Title_bottom{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 80vw;
    font-family: 'Poppins', sans-serif;
    margin-top: 5px;
    margin-bottom: 10vh;
    font-size: 1rem;
}
.OnePage_Title_bottom p{
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
}
.OnePage_video_action_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    margin-bottom: 5vh;
    border: solid 1px #FFF;
    font-family: "Poppins", sans-serif;
    cursor: pointer;
    padding: 10px;
    border-radius: 50px;
    transition: 0.3s;
}
.OnePage_video_action_button:hover{
    background-color: rgba(255, 255, 255, 0.1);
    color: #FFF;
    transition: 0.3s;
}
.OnePage_video_action_button:active{
    background-color: rgba(255, 255, 255, 1);
    color: #000;
    transition: 0.3s;
}
.OnePage_menu{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    background-color: #fff;
    background-color: rgb(255, 141, 115, 0.5);
}
.OnePage_menu_category{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    transition: 0.3s;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.OnePage_menu_category_title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    color: #c60000;
    padding: 10px;
    width: 100%;
}
.OnePage_menu_category_title_japanese{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #FFF;
    padding: 10px;
    border-radius: 10px;
    background-color: #c60000;
    margin-left: 10px;
}
.OnePage_menu_sub_category{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.3);
    padding-bottom: 0px;
}
.OnePage_menu_sub_category_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #000;
    padding: 15px 10px;
    text-transform: uppercase;
}
.OnePage_menu_sub_category_title_text{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #000;
    padding: 5px;
    border-radius: 5px;
    margin-left: 10px;
}
.OnePage_menu_sub_category_title_japanese{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #FFF;
    padding: 5px;
    border-radius: 5px;
    background-color: #000;
    margin-left: 10px;
}
.OnePage_menu_sub_category_description{
    width: 100%;
    display: flex;
    justify-content: left;
    text-align: left;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    color: #c60000;
    padding-left: 15px;
    margin-top: 10px;
}
.OnePage_menu_category_section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: fit-content;
    padding: 10px;
    transition: 0.3s;
}
.OnePage_menu_category_section_hidden{
    height: 0;
    overflow: hidden;
    transition: 0.3s;
}
.OnePage_menu_category_section_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    border-bottom: solid 1px #c60000;
}
.OnePage_menu_category_section_item_ref{
    flex: 1;
    width: fit-content;
    padding: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    color: #fff;
    background-color: #000;
    border-radius: 5px;
}
.OnePage_menu_category_section_item_title{
    flex: 5;
    padding: 5px;
    font-family: 'Poppins', sans-serif;
    text-align: left;
    font-size: 0.9rem;
    color: #000;
    margin: 0;
}
.OnePage_menu_category_section_item_price{
    flex: 2;
    padding: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #c60000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.OnePage_menu_category_pic_section_item_price{
    flex: 1;
    padding: 5px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #c60000;
    display: flex;
    justify-content: right;
    align-items: center;
}
.OnePage_wheretofindus_background{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../public/images/ounoustrouver.jpg);
}
.OnePage_wheretofindus_title{
    width: 100vw;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
}
.OnePage_wheretofindus_title_japanese{
    font-size: 1.2rem;
    color: #c60000;
    padding: 5px;
    margin: 0;
}
.OnePage_wheretofindus_zone{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #222;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
}
.OnePage_wheretofindus_address{
    width: 100vw;
    height: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #222;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
}
.OnePage_wheretofindus_map{
    width: 100vw;
    height: 70vw;
    background-color: #555;
}
.OnePage_wheretofindus_contact{
    width: 100vw;
    height: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #222;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
}
.OnePage_wheretofindus_hours{
    width: 100vw;
    height: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #222;
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
}
.OnePage_wheretofindus_address_title{
    font-size: 1.2rem;
    color: #FFF;
    padding: 10px;
    margin: 0;
    width: fit-content;
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}
.OnePage_wheretofindus_address_title svg{
    margin-right: 10px;
}
.OnePage_wheretofindus_address_content{
    font-size: 0.9rem;
    letter-spacing: 1px;
    color: #FFF;
    padding: 5px;
    margin: 0;
    width: 85%;
    text-align: center;
    padding: 10px;
    text-transform: none;
}
.OnePage_wheretofindus_phone_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70vw;
    margin-top: 2vh;
    padding: 10px;
    background-color: #222;
    color: #FFF;
    border: solid 1px #FFF;
    font-family: "Poppins", sans-serif;
    font-size: 1.5rem;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.3s;
}
.OnePage_wheretofindus_phone_button:hover{
    background-color: #FFF;
    color: #000;
    transition: 0.3s;
}
.OnePage_menu_category_section_item_with_image{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    padding: 10px;
    background-color: #FFF;
    border-radius: 16px;
    margin-top: 10px;
    flex-wrap: wrap;
}
.OnePage_menu_category_section_item_description{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: left;
    font-size: 0.8rem;
    padding: 10px;
}
.OnePage_menu_category_section_item_image{
    width: 25vw;
    aspect-ratio: 1/1;
    border-radius: 10px;
    margin-right: 10px;
    
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.OnePage_menu_category_pic_section_title_zone{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    height: 100%;
    width: 65%;
}


@media screen and (min-width: 768px){
    .OnePage_Title{
        width: fit-content;
    }
    .OnePage_japanese_title{
        font-size: 3rem;
    }
    .OnePage_Title_right h1{
        font-size: 5rem;
    }
    .OnePage_Title_left{
        font-size: 1.2rem;
        width: calc(10px + 1.2rem);
    }
    .OnePage_Title_bottom p{
        text-align: center;
        width: 100%;
        font-size: 1.5rem;
        text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
    }
    .OnePage_video_action_button{
        font-size: 1.5rem;
        max-width: 300px;
    }
    .OnePage_menu_category{
        max-width: 800px;
        margin: 10px;
        border-radius: 10px;
    }
    .OnePage_menu_sub_category{
        max-width: 800px;
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 10px;
    }
    .OnePage_menu_sub_category_title_text{
        margin-left: 0px;
    }
    .OnePage_menu_category_section{
        max-width: 800px;
    }
    .OnePage_menu_category_section_item{
        max-width: 800px;
    }
    .OnePage_menu_category_section_item_ref{
        font-size: 1rem;
    }
    .OnePage_wheretofindus{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100vw;
        background-color: #222;
        scroll-behavior: smooth;
    }
    .OnePage_wheretofindus_address_title{
        font-size: 1.5rem;
        max-width: 800px;
    }
    .OnePage_wheretofindus_address_content{
        font-size: 1.2rem;
        max-width: 800px;
    }
    .OnePage_wheretofindus_zone{
        flex-direction: row;
        flex-wrap: wrap;
        width: 80vw;
        max-width: 800px;
    }
    .OnePage_wheretofindus_address{
        width: 50%;
        max-width: 400px;
        height: unset;
        aspect-ratio: 1/1;
    }
    .OnePage_wheretofindus_map{
        width: 50%;
        max-width: 400px;
        height: unset;
        aspect-ratio: 1/1;
    }
    .OnePage_wheretofindus_contact{
        width: 50%;
        max-width: 400px;
        height: unset;
        aspect-ratio: 1/1;
    }
    .OnePage_wheretofindus_hours{
        width: 50%;
        max-width: 400px;
        height: unset;
        aspect-ratio: 1/1;
    }
    .OnePage_wheretofindus_phone_button{
        width: 30vw;
        max-width: 300px;
    }
    .OnePage_menu_sub_category{
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
    .OnePage_menu_category_section_item_with_image{
        width: 49%;
    }
    .OnePage_menu_category_section_item_image{
        width: 150px;
        aspect-ratio: 1/1;
        flex: 4;
    }
    .OnePage_menu_category_pic_section_title_zone{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 150px;
        flex: 4;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
    .OnePage_menu_category_pic_section_item_price{
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        height: 150px;
        color: #c60000;
        flex: 2;
    }
    .OnePage_menu_category_section_item_description{
        width: 100%;
        padding: 0;
        font-size: 0.8rem;
        text-align: left;
    }
}