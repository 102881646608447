.ClickandCollect{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #f5f5f5;
}
.ClickandCollect_screen{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-image: url('../../public/images/background.jpg');
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
.ClickandCollect_background{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    color: #f5f5f5;
}
.ClickandCollect_Title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    font-family: 'Poppins', sans-serif;
}
.ClickandCollect_Title_Fr{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
}
.ClickandCollect_Title_Jp{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #c60000;
}
.ClickandCollect_Explication{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    margin-top: 5vh;
}
.ClickandCollect_Shops_List_back_button_zone{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.ClickandCollect_Shops_List_back_button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #FFF;
    background-color: #444;
    padding: 10px 15px;
    border-radius: 3px;
    cursor: pointer;
}
.ClickandCollect_Shops_List_title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    margin-top: 3vh;
    margin-bottom: 5vh;
}
.ClickandCollect_Shops_List{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90vw;
    margin-top: 3vh;
    margin-bottom: 5vh;
}
.ClickandCollect_Shop_case{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    height: 25vw;
    margin-top: 2vw;
    background-color: #FFF;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.10);
    overflow: hidden;
}
.ClickandCollect_Shop_case_title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: #FFF;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
}
.ClickandCollect_Box_List{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
    margin-bottom: 5vh;
}
.ClickandCollect_Box_case{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    width: 90%;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.10);
    background-color: #FFF;
}
.ClickandCollect_Box_case_title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #000;
    width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 3px;
}
.ClickandCollect_Box_case_price{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #FFF;
    background-color: #555;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 20%;
    padding: 10px;
    border-radius: 3px;
}
.ClickandCollect_Box_case_image{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    width: 100%;
    height: 30vh;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

}
.ClickandCollect_Box_case_description{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    text-align: left;
    color: #444;
    width: 100%;
    padding: 10px;
}
.ClickandCollect_Box_case_button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #FFF;
    background-color: #555;
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    margin-top: 5px;
    cursor: pointer;
}
.ClickandCollect_Box_case_button:hover{
    background-color: #333;
}
.ClickandCollect_Box_case_number_of_box{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #000;
    width: 100%;
    padding: 10px;
    border-radius: 3px;
}
.ClickandCollect_Box_case_number_of_box_minus{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #FFF;
    background-color: #555;
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    margin-top: 5px;
    cursor: pointer;
}
.ClickandCollect_Box_case_number_of_box_minus:hover{
    background-color: #333;
}
.ClickandCollect_Box_case_number_of_box_plus{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #FFF;
    background-color: #555;
    width: 100%;
    padding: 10px;
    border-radius: 3px;
    margin-top: 5px;
    cursor: pointer;
}
.ClickandCollect_Box_case_number_of_box_plus:hover{
    background-color: #333;
}
.ClickandCollect_Box_case_number_of_box_quantity{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #000;
    width: 100%;
    padding: 10px;
    border-radius: 3px;
}
.ClickandCollect_Validation{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 5px;
    padding: 10px;
    width: 90vw;
    margin-bottom: 5vh;
    cursor: pointer;
    border: solid 2px #AAA;
    transition: 0.3s;
}
.ClickandCollect_Validation:hover{
    background-color: #AAA;
    color: #FFF;
    transition: 0.3s;
}
.ClickandCollect_Custommer_info_zone{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    margin-top: 5vh;
}
.ClickandCollect_Custommer_info_title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    margin-bottom: 5vh;
}
.ClickandCollect_Custommer_info_form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
}
.ClickandCollect_Custommer_info_form input{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    width: 90%;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: 1px solid #AAA;
}
.ClickandCollect_Custommer_info_validation{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #FFF;
    background-color: #555;
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5vh;
    cursor: pointer;
    transition: 0.3s;
}
.ClickandCollect_Custommer_info_validation:hover{
    background-color: #333;
    transition: 0.3s;
}
.ClickandCollect_Custommer_info_validation_back_zone{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90vw;
    margin-bottom: 5vh;
}
.ClickandCollect_Custommer_info_validation_back{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #FFF;
    background-color: #555;
    padding: 10px;
    border-radius: 5px;
    margin-top: 5vh;
    cursor: pointer;
    transition: 0.3s;
}
.ClickandCollect_Custommer_info_validation_back:hover{
    background-color: #333;
    transition: 0.3s;
}
.ClickandCollect_Payment_zone{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    margin-top: 5vh;
}
.ClickandCollect_Payment_title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    margin-bottom: 5vh;
}
.ClickandCollect_Payment_back_button_zone{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90vw;
    margin-bottom: 5vh;
}
.ClickandCollect_Payment_back_button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #FFF;
    background-color: #555;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.ClickandCollect_Payment_total{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    margin-top: 1vh;
    margin-bottom: 1vh;
    background-color: #DDD;
    padding: 15px;
    border-radius: 5px;
    width: 90vw;
}
.ClickandCollect_Payment_form_taxe{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    width: 90vw;
    color: #555;
}
.ClickandCollect_Payment_Stripe_Zone{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
}
.ClickandCollect_Payment_Stripe_input{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    width: 90%;
    padding: 10px;
    margin-top: 15px;
    border-radius: 5px;
    border: 1px solid #AAA;
}
.ClickandCollect_Payment_validation{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #FFF;
    background-color: #555;
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5vh;
    cursor: pointer;
    transition: 0.3s;
}
.ClickandCollect_Payment_validation:hover{
    background-color: #333;
    transition: 0.3s;
}
.ClickandCollect_Thanks{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    margin-top: 3vh;
}
.ClickandCollect_Thanks h2{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    margin-bottom: 1vh;
    color: #222;
}
.ClickandCollect_Thanks_sentence{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin-bottom: 4vh;
    color: #222;
}
.ClickandCollect_Thanks_commande{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin-bottom: 5vh;
    background-color: #DDD;
    border-radius: 5px;
    width: 95vw;
}
.ClickandCollect_Thanks_commande h3{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    padding: 15px;
    color: #333;
}
.ClickandCollect_Thanks_commande_id{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    margin-bottom: 5vh;
    background-color: #555;
    color: #FFF;
    width: 95%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.ClickandCollect_Thanks_commande_detail{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.ClickandCollect_Thanks_commande_item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    width: 100%;
    padding: 10px 15px;
    border-bottom: solid 1px #555;
}
.ClickandCollect_Thanks_commande_total{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    background-color: #555;
    color: #FFF;
    width: 100%;
    padding: 10px;
    border-radius: 0px 0px 5px 5px;
}
.ClickandCollect_Thanks_commande_back_button{
    width: 95vw;
    border: solid 2px #555;
    border-radius: 5px;
    padding: 10px;
    margin-top: 2vh;
    margin-bottom: 5vh;
    color: #555;
    cursor: pointer;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 1px;
    transition: 0.3s;
}
.ClickandCollect_Thanks_commande_back_button:hover{
    background-color: #555;
    color: #FFF;
    transition: 0.3s;
}
.ClickandCollect_Custommer_info_time_slot_list{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    margin-top: 5vh;
}
.ClickandCollect_Custommer_info_time_slot_case{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    margin-top: 2vh;
    background-color: #FFF;
    border-radius: 5px;
    border: solid 1px #DDD;
    cursor: pointer;
    transition: 0.3s;
}
.ClickandCollect_Custommer_info_time_slot_case:hover{
    background-color: #EEE;
    transition: 0.3s;
}
.ClickandCollect_Custommer_info_time_slot_case_selected{
    border: solid 1px #63b378;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    margin-top: 2vh;
    background-color: #e9fdf4;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}
.ClickandCollect_Custommer_info_time_slot_case_day{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #555;
    width: 100%;
    padding: 10px;
    border-bottom: solid 1px #DDD;
}
.ClickandCollect_Custommer_info_time_slot_case_hour{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: #444;
    background-color: #DDD;
    width: 100%;
    padding: 10px;
}