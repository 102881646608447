.Dashboard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
}
.Dashboard_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    padding: 15px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    position: fixed;
    top: 0;
    left: 0;
    background-color: #FFF;
    z-index: 100;
    font-weight: 600;
}

.Dashboard_title_text{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    flex: 5;

}
.Dashboard_title_japanese{
    color: #c60000;
    font-weight: 400;
}
.Dashboard_title_logout{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #c60000;
    color: #FFF;
    cursor: pointer;
    transition: 0.3s;
}

.Dashboard_content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    background-color: rgba(198, 0, 0, 0.08);
    height: 100vh;
    overflow-y: auto;
    /* Masquer la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.Dashboard_content_page{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    margin-top: 10vh;
}
.Dashboard_content_section{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    margin-top: 10px;
    max-height: 75vh;
    overflow-x: auto;
    /* Masquer la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.Dashboard_content_articles_section{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #FFF;
    margin-top: 10px;
    overflow-x: auto;
    /* Masquer la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.Dashboard_content_title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    background-color: #c60000;
    color: #FFF;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 100;

}
.Dashboard_content_section_order{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    background-color: #FFF;
    border-bottom: solid 1px #EEE;
}
.Dashboard_content_section_order_title{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    border: solid 1px #AAA;
    border-radius: 5px;
    background-color: #FFF;
    cursor: pointer;
    transition: 0.3s;
}
.Dashboard_content_section_order_title:hover{
    background-color: #EEE;
    transition: 0.3s;
}
.Dashboard_content_section_order_title:active{
    background-color: #DDD;
    transition: 0.3s;
}
.Dashboard_content_section_order_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #EEE;
}
.Dashboard_content_section_order_input{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_order_time_spots{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_order_time_spot{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_order_time_spot_day{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_order_time_spot_day_title{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #DDD;
    margin-top: 10px;
    margin-bottom: 1vh;
    width: 100%;

}
.Dashboard_content_section_order_time_spot_type{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    background-color: #FFF;
    margin-top: 10px;
    margin-bottom: 1vh;
    width: 100%;
}
.Dashboard_content_section_order_time_spot_hours{
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.Dashboard_content_section_order_time_spot_hour{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF;
}
.Dashboard_content_section_order_time_spot_hour_title{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #DDD;
    margin-top: 10px;
    margin-bottom: 1vh;
    width: 100%;
}
.Dashboard_content_section_order_time_spot_hour_checkbox{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFF;
    margin-top: 10px;
    margin-bottom: 1vh;
    width: 100%;
}
.Dashboard_content_categories_edit{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    cursor: pointer;
}
.Dashboard_content_categories_edit_button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFF;
    margin-top: 10px;
    cursor: pointer;
    border: solid 1px #DDD;
    transition: 0.3s;
}
.Dashboard_content_categories_edit_button:hover{
    background-color: #EEE;
    transition: 0.3s;
}
.Dashboard_content_categories_edit_content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: #000;
    border-radius: 10px;
}
.Dashboard_content_categories_edit_content_name{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
}
.Dashboard_content_categories_edit_content_description{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
}
.Dashboard_content_categories_edit_content_button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #c60000;
    color: #FFF;
    cursor: pointer;
    transition: 0.3s;
}
.Dashboard_content_categories_edit_content_button:hover{
    background-color: #a50101;
    transition: 0.3s;
}
.Dashboard_content_categories_edit_content_back{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFF;
    cursor: pointer;
    border: solid 1px #DDD;
    transition: 0.3s;
}
.Dashboard_content_section_order_button{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #c60000;
    color: #FFF;
    margin-top: 10px;
    cursor: pointer;
}
.Dashboard_content_section_order:nth-child(odd){
    background-color: rgb(198, 0, 0, 0.1);
}
.Dashboard_content_section_order:nth-child(even){
    background-color: #FFF;
}
.Dashboard_content_section_order_number{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: #a50101;
    color: #FFF;
    padding: 10px;
    border-radius: 5px 0px 0px 5px;
}
.Dashboard_content_section_order_name{
    flex: 4;
    align-items: flex-start;
    text-align: left;
}
.Dashboard_content_section_order_last_order_date{
    flex: 3;
    text-align: right;
}
.Dashboard_content_section_order_nom{
    flex: 4;
    align-items: center;
    text-align: left;
    pointer-events: none;
    padding: 0px 10px;
    display: flex;
}
.Dashboard_content_section_order_
.Dashboard_content_section_order_nom{
    flex: 4;
    align-items: flex-start;
    text-align: left;
    pointer-events: none;
}
.Dashboard_content_section_order_status{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}
.Dashboard_content_section_order_pastille{
    width: 1.4rem;
    height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: #FFF;
    background-color: #CCC;
    border-radius: 50%;
    pointer-events: none;
}
.Dashboard_content_section_order_pastille_red{
    width: 1.4rem;
    height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    color: #FFF;
    background-color: #c60000;
    border-radius: 50%;
}
.Dashboard_content_section_order_date{
    flex: 2;
}
.Dashboard_content_section_order_status{
    flex: 2;
}
.Dashboard_content_section_order_price{
    flex: 1;
}
.Dashboard_content_articles_list{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    margin-top: 10px;
    overflow-x: auto;
    /* Masquer la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.Dashboard_content_article{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    background-color: #FFF;
    border-bottom: solid 1px #EEE;
}
.Dashboard_content_article:nth-child(odd){
    background-color: rgb(198, 0, 0, 0.1);
}
.Dashboard_content_article:nth-child(even){
    background-color: #FFF;
}
.Dashboard_content_article_name{
    flex: 4;
    align-items: flex-start;
    text-align: left;
}
.Dashboard_content_article_quantity{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Dashboard_content_article_check{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.Dashboard_content_client_info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #FFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    margin-top: 10px;
    overflow-x: auto;
    /* Masquer la barre de défilement */
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }
}
.Dashboard_content_client_info_title{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    background-color: #c60000;
    color: #FFF;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 100;
}
.Dashboard_content_client_info_line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    background-color: #FFF;
    border-bottom: solid 1px #EEE;
}
.Dashboard_content_client_info_line:nth-child(odd){
    background-color: rgb(198, 0, 0, 0.1);
}
.Dashboard_content_client_info_line:nth-child(even){
    background-color: #FFF;
}
.Dashboard_content_client_info_line_title{
    flex: 4;
    align-items: flex-start;
    text-align: left;
}
.Dashboard_content_client_info_line_value{
    flex: 4;
    align-items: flex-start;
    text-align: left;
}
.Dashboard_menu{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    border-top: solid 1px #FFF;
    background-color: #c60000;
    z-index: 100;
}
.Dashboard_menu_section{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    color: #FFF;
    transition: 0.2s;
}
.Dashboard_menu_section_active{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.8rem;
    color: #c60000;
    background-color: #FFF;
    transition: 0.2s;
}
.Dashboard_menu_back_button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    
    padding: 10px;
    background-color: #c60000;
    color: #FFF;
    z-index: 100;
}
.Dashboard_menu_back_button .ArrowLeftSquare{
    margin-right: 10px;
}
.Dashboard_content_section_articles{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 1px;
    padding-bottom: 10px;
}
.Dashboard_content_section_article{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 95%;
    background-color: #FFF;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.15);
    margin-bottom: 2px;
}
.Dashboard_content_section_article_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    background-color: #c60000;
    color: #FFF;
}
.Dashboard_content_section_article_name{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_article_ref{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_article_description{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_article_image{
    width: 100%;
    aspect-ratio: 1/1;
    background-size: cover;
    background-position: center;
    background-color: #555;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 10px;
}
.Dashboard_content_section_article_image_input{
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 0px;
    margin-bottom: 1vh;
    padding: 5px;
    background-color: #FFF;
    height: 44px;
    width: 44px;
    cursor: pointer;
}
.Dashboard_content_section_article_image_input_mask{
    width: 44px;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-bottom: -44px;
    height: 44px;
    z-index: 100;
    padding: 5px;
    background-color: #EEE;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    pointer-events: none;
}
.Dashboard_content_section_article_category{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
}
.Dashboard_content_section_article_sub_category{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
}
.Dashboard_content_section_article_price{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
}
.Dashboard_content_section_article_status_buttons{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
}
.Dashboard_content_section_article_status_button{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #222;
    color: #FFF;
    margin-top: 10px;
    cursor: pointer;
}
.Dashboard_content_section_article_status_button_actif{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: #0be486;
    color: #FFF;
    margin-top: 10px;
    cursor: pointer;
}
.Dashboard_content_section_article_number{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 5vh;
}
.Dashboard_content_more_article_button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #222;
    margin-top: 10px;
    cursor: pointer;
    border: solid 1px #DDD;
}
.Dashboard_content_more_article_button:hover{
    background-color: #EEE;
}
.Dashboard_content_more_magasin_button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #222;
    margin-top: 10px;
    cursor: pointer;
    border: solid 1px #DDD;
}
.Dashboard_content_more_client_button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #222;
    margin-top: 10px;
    cursor: pointer;
    border: solid 1px #DDD;
}
.Dashboard_content_more_order_button{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #222;
    margin-top: 10px;
    cursor: pointer;
    border: solid 1px #DDD;
}
.Dashboard_content_article_more_button{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #222;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: solid 1px #DDD;
}
.Dashboard_content_article_name{
    flex: 7;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_article_name_input{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
    padding: 5px;
}
.Dashboard_content_article_quantity{
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_article_quantity_input{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
    padding: 5px;
}
.Dashboard_content_article_check{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_article_check_input{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
    padding: 5px;
}
.Dashboard_content_article_search_results{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}
.Dashboard_content_section_category{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.Dashboard_content_section_category_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    background-color: #000;
    color: #FFF;
}
.Dashboard_content_section_category_articles{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
}
.Dashboard_content_section_new_sub_cat_form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
    background-color: #DDD;
    border-radius: 10px;
}
.Dashboard_content_section_new_sub_cat_form_input{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_new_sub_cat_form_textarea{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_new_sub_cat_form_button{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #c60000;
    color: #FFF;
    margin-top: 10px;
    cursor: pointer;
}
.Dashboard_content_section_new_sub_cat_section{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}
.Dashboard_content_section_new_sub_cat_form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}
.Dashboard_content_section_order_account_section{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}
.Dashboard_content_section_order_account_title{
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    background-color: #555;
    border-radius: 5px;
    margin-top: 10px;
    color: #FFF;
}
.Dashboard_content_section_order_account_text{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px #AAA;
    margin-top: 10px;
    margin-bottom: 1vh;
}
.Dashboard_content_section_order_account_button{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #c60000;
    color: #FFF;
    margin-top: 10px;
    cursor: pointer;
}
.Dashboard_content_section_article_danger_zone{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}
.Dashboard_content_section_article_danger_zone_button {
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #c60000;
    color: #FFF;
    margin-top: 10px;
    cursor: pointer;
}
.Dashboard_content_section_new_sub_cat_button{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    border: solid 1px #DDD;
    color: #222;
}
.Dashboard_content_section_order_magasin{
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 10px;
}